<template>
  <div class="rentSuccess">
    <div class="top-img"><img src="./img/rentSuccess.png" alt="" /></div>
    <div class="text">您的申请已经提交成功～</div>
    <div class="btn" @click="toIndex">返回首页</div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
export default {
  name: "rentSuccess",
  data() {
    return {};
  },
  components: {},
  destroyed() {},
  computed: {},
  created() {},
  mounted() {},
  methods: {
    toIndex() {
      wx.miniProgram.navigateTo({
        url: `/pages/index/index`,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.rentSuccess {
  min-height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
  padding-top: 42px;
  .top-img {
    width: 282px;
    height: 252px;
    margin: 0 auto;
    padding-left: 70px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .text {
    font-size: 32px;
    text-align: center;
    font-weight: 400;
    color: #666666;
    line-height: 44px;
    margin: 52px auto 156px;
  }
  .btn {
    width: 686px;
    height: 96px;
    border-radius: 48px;
    border: 2px solid #007eff;
    margin: 0 auto;
    font-size: 32px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #007eff;
    line-height: 96px;
    text-align: center;
  }
}
</style>
<style lang="less">
.rentSuccess {
}
</style>
